<template>
  <div id="container" class="pace-top app app-full-height app-without-header">
    <!-- BEGIN login -->
    <component :is="currentPage" :username="username"></component>
    <!-- END login -->
    
    <!-- BEGIN btn-scroll-top -->
    <a href="#" data-toggle="scroll-to-top" class="btn-scroll-top fade"><i class="fa fa-arrow-up"></i></a>
    <!-- END btn-scroll-top -->

    <ThemePanel v-show="showThemePanel" ref="themePanel"></ThemePanel>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import ThemePanel from "@/components/ThemePanel.vue"
// import { Auth } from 'aws-amplify';
import { defineAsyncComponent, markRaw } from 'vue'

const LoginPage = defineAsyncComponent(() =>
  import("@/components/Auth/LoginPage.vue")
)
const RegisterPage = defineAsyncComponent(() =>
  import("@/components/Auth/RegisterPage.vue")
)
const RegisterConfirmationPage = defineAsyncComponent(() =>
  import("@/components/Auth/RegisterConfirmationPage.vue")
)
const PasswordRecoveryPage = defineAsyncComponent(() =>
  import("@/components/Auth/PasswordRecoveryPage.vue")
)

export default {
  name: 'AuthView',
  components: {
    ThemePanel
  },

  data() {
    return {
      LoginPage: markRaw(LoginPage),
      RegisterPage: markRaw(RegisterPage),
      RegisterConfirmationPage: markRaw(RegisterConfirmationPage),
      PasswordRecoveryPage: markRaw(PasswordRecoveryPage),

      message: "hello, world",
      // currentPage: "Login",
      username: "",
      emailRegEx: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      showThemePanel: false,
    }
  },
  computed: {
    currentPage() {
      return this[this.$route.name];
    }
  },

  methods: {
    openPage(page, username) {
      this.username = username;
      // this.currentPage = page;
      this.$router.push({ name: page });
    },

    isEmpty(text) {
      return (text == "");
    },
    validateEmail(email) {
      if (this.isEmpty(email))
        return false;
      return this.emailRegEx.test(email);
    }
  },

  mounted() {
    window.addEventListener('keydown', (e) => {
      if (e.key == 'F2') {
        // console.log("F2 pressed");
        this.showThemePanel = !this.showThemePanel;
      }
    });
  },
}

</script>
